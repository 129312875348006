import { IWebApi } from "./interfaces/IWebApi";

export class Interoperability {
    public static WebApi = class {
        public static TypeGuards = class {
            public static DetermineIfXrmImplementation(toBeDetermined: object): toBeDetermined is Xrm.WebApi {
                if ((toBeDetermined as Xrm.WebApi)?.online) { //check if the object contains online property
                    return true;
                }
                return false;
            }
            // TODO: This implementation is very weird
            public static DetermineIfPCFImplementation(toBeDetermined: object): toBeDetermined is ComponentFramework.WebApi {
                if (!(toBeDetermined as Xrm.WebApi)?.online) { //check if the object contains online property
                    return true;
                }
                return false;
            }
        };
        public static TryGetXrmImplementation(webApi: IWebApi | Xrm.WebApi | ComponentFramework.WebApi): Xrm.WebApi {
            if (this.TypeGuards.DetermineIfXrmImplementation(webApi)) {
                return webApi;
            }
            else if (this.TypeGuards.DetermineIfPCFImplementation(webApi)) {
                const fakeXrmWebApi: Xrm.WebApi = {
                    ///@ts-ignore - We are not implementing PromiseLike
                    // eslint-disable-next-line -- any comes from Xrm types
                    async execute(request: any): Promise<Response> {
                        ///@ts-ignore - It doesn't contain execute in DefinitelyTyped types
                        return webApi.execute(request);
                    },
                    ///@ts-ignore - We are not implementing PromiseLike
                    // eslint-disable-next-line -- any comes from Xrm types
                    async executeMultiple(requests: any[]): Promise<Response[]> {
                        let responses = [];
                        for (const request of requests) {
                            ///@ts-ignore - It doesn't contain execute in DefinitelyTyped types
                            responses.push(await webApi.execute(request));
                        }
                        return responses;
                    },

                    ///@ts-ignore - We are not implementing PromiseLike
                    // eslint-disable-next-line -- any comes from Xrm types
                    async createRecord(entityLogicalName: string, record: any): Promise<Xrm.CreateResponse> {
                        const result = await webApi.createRecord(entityLogicalName, record);

                        return {
                            entityType: entityLogicalName,
                            id: result.id
                        };
                    },
                    ///@ts-ignore - We are not implementing PromiseLike
                    async deleteRecord(entityLogicalName: string, id: string): Promise<string> {
                        const result = await webApi.deleteRecord(entityLogicalName, id);

                        ///@ts-ignore - As per https://docs.microsoft.com/en-us/powerapps/developer/model-driven-apps/clientapi/reference/xrm-webapi/deleterecord#return-value an object should be returned instead of a string. This is likely a bug in @types/xrm
                        return {
                            entityType: entityLogicalName,
                            id: result.id
                        };
                    },
                    ///@ts-ignore - We are not implementing PromiseLike
                    // eslint-disable-next-line -- any comes from Xrm types
                    async retrieveRecord(entityLogicalName: string, id: string, options?: string): Promise<any> {
                        const result = await webApi.retrieveRecord(entityLogicalName, id, options);

                        return result;
                    },
                    ///@ts-ignore - We are not implementing PromiseLike
                    async retrieveMultipleRecords(entityLogicalName: string, options?: string, maxPageSize?: number): Promise<Xrm.RetrieveMultipleResult> {
                        const result = await webApi.retrieveMultipleRecords(entityLogicalName, options, maxPageSize);

                        return result;
                    },
                    ///@ts-ignore - We are not implementing PromiseLike
                    // eslint-disable-next-line -- any comes from Xrm types
                    async updateRecord(entityLogicalName: string, id: string, data: any): Promise<any> {
                        const result = await webApi.updateRecord(entityLogicalName, id, data);

                        return {
                            entityType: entityLogicalName,
                            id: result.id
                        };
                    }

                };
                fakeXrmWebApi.online = fakeXrmWebApi as unknown as Xrm.WebApiOnline;
                fakeXrmWebApi.offline = fakeXrmWebApi as unknown as Xrm.WebApiOffline;
                return fakeXrmWebApi;
            }
            else {
                throw Error('It is not possible to convert provided WebApi implementation to Xrm.WebApi');
            }
        }
    };
}
export class UCIClientExtensions {
   constructor() { }

   public static tryGetFormContext(executionContext: Xrm.Events.EventContext | Xrm.FormContext): Xrm.FormContext {
      if (this._typeGuards.DetermineIfContextIsEventContext(executionContext)) {
         return executionContext.getFormContext();
      }
      else {
         return (executionContext as Xrm.FormContext);
      }
   }

   private static _typeGuards = class {
      public static DetermineIfContextIsEntityForm(toBeDetermined: object): toBeDetermined is Xrm.FormContext {
         if ((toBeDetermined as Xrm.FormContext).data?.entity) { //check if the object contains entity property
            return true;
         }
         return false;
      }

      public static DetermineIfContextIsDialog(toBeDetermined: object): toBeDetermined is Xrm.FormContext {
         if (!this.DetermineIfContextIsEntityForm(toBeDetermined)) {
            if ((toBeDetermined as Xrm.FormContext).ui) {
               return true;
            }
         }
         return false;
      }

      public static DetermineIfContextIsEventContext(toBeDetermined: object): toBeDetermined is Xrm.Events.EventContext {
         if ((toBeDetermined as Xrm.Events.EventContext).getFormContext) {
            return true;
         }
         return false;
      }
   };
}
